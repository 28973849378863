import React, { Component } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

class Contact_Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            errors: {},
            submissionStatus: '',
            isLoading: false,
            recaptchaToken: ''
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validateForm = () => {
        const { name, email, subject, message } = this.state;
        const errors = {};
        if (!name) errors.name = 'Il nome è obbligatorio';
        if (!email) errors.email = "L'email è obbligatoria";
        if (!subject) errors.subject = "L'oggetto è obbligatorio";
        if (!message) errors.message = 'Il messaggio è obbligatorio';
        return errors;
    }

    handleVerifyRecaptcha = (token) => {
        this.setState({ recaptchaToken: token });
    }

    onVerify = (token) => {
        this.setState({ recaptchaToken: token });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validateForm();
        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }

        if (!this.state.recaptchaToken) {
            this.setState({ errors: { recaptcha: 'Per favore completa il reCAPTCHA' } });
            return;
        }

        this.setState({ isLoading: true });

        const { name, email, subject, message, recaptchaToken } = this.state;
        const formData = { name, email, subject, message, recaptchaToken };

        try {
            const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const result = await response.json();
            if (response.ok) {
                this.setState({ submissionStatus: 'Modulo inviato con successo!', errors: {}, isLoading: false, recaptchaToken: '' });
                setTimeout(() => {
                    window.location.reload();
                }, 5000); // Reload the page after 5 seconds
            } else {
                this.setState({ submissionStatus: 'Invio del modulo fallito!', errors: result.errors, isLoading: false });
            }
        } catch (error) {
            this.setState({ submissionStatus: 'Invio del modulo fallito!', errors: { general: 'Si è verificato un errore. Per favore riprova.' }, isLoading: false });
        }
    }

    render() {
        const { name, email, subject, message, errors, submissionStatus, isLoading } = this.state;
        let imgalt = 'immagine';
        let publicUrl = process.env.PUBLIC_URL + '/';

        return (
            <div>
                <div className="contact-form-area pd-top-112">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="section-title text-center w-100">
                                <h2 className="title">Invia la tua <span>richiesta</span></h2>
                                <p>
                                    Siamo sempre pronti ad aiutarti. Ci sono molti modi per contattarci. Puoi lasciarci un messaggio, chiamarci o inviarci un'email, scegli quello che ti è più comodo.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5">
                                <img src={publicUrl + 'assets/img/others/21.png'} alt={imgalt} />
                            </div>
                            <div className="col-lg-7 offset-xl-1">
                                <form className="trucksout-form-wrap mt-5 mt-lg-0" onSubmit={this.handleSubmit}>
                                    <div className="row custom-gutters-16">
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <label>Nome</label>
                                                <input type="text" className="single-input" name="name" value={name} onChange={this.handleChange} />
                                                {errors.name && <span className="error">{errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-input-wrap">
                                                <label>Email</label>
                                                <input type="email" className="single-input" name="email" value={email} onChange={this.handleChange} />
                                                {errors.email && <span className="error">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-input-wrap">
                                                <label>Oggetto</label>
                                                <input type="text" className="single-input" name="subject" value={subject} onChange={this.handleChange} />
                                                {errors.subject && <span className="error">{errors.subject}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="single-input-wrap">
                                                <label className="single-input-label">Messaggio</label>
                                                <textarea className="single-input textarea" name="message" value={message} onChange={this.handleChange} cols="20"></textarea>
                                                {errors.message && <span className="error">{errors.message}</span>}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <GoogleReCaptcha
                                                action='submit'
                                                onVerify={this.onVerify}
                                            />
                                            {errors.recaptcha && <span className="error">{errors.recaptcha}</span>}
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-blue mt-0" disabled={isLoading}>
                                                {isLoading ? 'Invio in corso...' : 'Invia'}
                                            </button>
                                            {submissionStatus && (
                                                <div className={`submission-status ${submissionStatus.includes('successo') ? 'success' : 'error'}`}>
                                                    {submissionStatus}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const WrappedContactForm = () => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <Contact_Form />
    </GoogleReCaptchaProvider>
);

export default WrappedContactForm;